<template>
  <div class="bg">
    <div class="top">
      <img src="../../../assets/img/inquiry_10.png" alt="" />
      <div>
        <h3>王涛 <span class="f14">主任医师</span></h3>
        <p class="mb8">中国人民解放军总医院301医院</p>
        <p>呼吸科</p>
      </div>
    </div>
    <div class="top2">
      <h1>患者信息</h1>
      <p>张三丰 男 35岁</p>
    </div>
    <h1 class="h1">服务信息</h1>
    <div class="top3">
      <p>1. 30天，独家健康指导</p>
      <p>2. 健康咨询，不限次数，不限时间</p>
      <p>3.患病驻院，实时指导</p>
      <p>4.xxxxxx的权益</p>
    </div>
    <div class="btnbox">
      <van-button
        round
        type="info"
        size="large"
        @click="gonav('privatedoctorordeinfo')"
        color="rgba(29,174,252,1)"
        >健康咨询</van-button
      >
    </div>
    <h1 class="h1">订单信息</h1>
    <div class="top3">
      <p>订单编号：20200308095500</p>
      <p>创建日期：2020-08-03 18:23:39</p>
      <p>问诊金额：20.00元</p>
      <p>实际支付金额：20.00元</p>
      <p>支付流水号：2019091014301522</p>
      <p>付款日期：2020-08-03 18:25:32</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    gonav(url) {},
  },
};
</script>

<style lang="less" scoped>
.bg {
  background: #fff;
  padding: 16px;
  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: left;
    color: #444444;
    margin-bottom: 16px;
  }
  .top {
    height: 113px;

    display: flex;
    text-align: left;
    font-size: 12px;
    margin-bottom: 32px;
    h3 {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    img {
      display: block;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 16px;
    }
  }
  .top2 {
    width: 343px;
    height: 80px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 24px;
    h1 {
      font-weight: 400px;
      margin-bottom: 8px;
    }
  }
  .top3 {
    width: 343px;
    // height: 106px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 24px;
  }
  .btnbox {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
</style>
